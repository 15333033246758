// colors
$primary-color: #00194d;
$primary-color-rgb: 0, 25, 77;
$primary-color-contrast: #ffffff;
$primary-color-contrast-rgb: 255, 255, 255;
$secondary-color: #175ce5;
$secondary-color-rgb: 23, 92, 229;
$secondary-color-contrast: #ffffff;
$secondary-color-contrast-rgb: 255, 255, 255;
$warning-color: #ffce00;
$warning-color-rgb: 255, 206, 0;
$warning-color-contrast: #ffffff;
$warning-color-contrast-rgb: 255, 255, 255;
$danger-color: #ff1900;
$danger-color-rgb: 255, 25, 0;
$danger-color-contrast: #ffffff;
$danger-color-contrast-rgb: 255, 255, 255;
$dark-color: #878787;
$dark-color-rgb: 135, 135, 135;
$dark-color-contrast: #ffffff;
$dark-color-contrast-rgb: 255, 255, 255;
$medium-color: #c6c6c6;
$medium-color-rgb: 198, 198, 198;
$medium-color-contrast: #ffffff;
$medium-color-contrast-rgb: 255, 255, 255;
$light-color: #CCCCCC;
$light-color-rgb: 204, 204, 204;
$light-color-contrast: #000000;
$light-color-contrast-rgb: 0, 0, 0;
// fonts
$default-font: 'Titillium Web', "Roboto", sans-serif;
$default-font-size: 16px;
// left menu
$default-logo-file: 'images/theme/logo.jpg';
$bg-menu-color: #a51129;
$bg-menu-image: 'bg-menu.png';
$menu-color: #ffffff; //#D3D6D9;
$menu-color-highlight: lighten($menu-color, 25%);
$color-memu-highlight: #db9e27;
$bg-overlay: $primary-color; //#525E6A;
$bg-overlay-rgb: $primary-color-rgb;
// dashboard
$bg-dashboard-color: #f39100;
$bg-dashboard-image: 'bg-dashboard.png';
// main content
$bg-content: #F8F8F8;
$bg-content-contrast: #FFFFFF;
$color-content: $dark-color;
$bg-content-logged-out: #FFFFFF url('/assets/images/theme/background.png') no-repeat top right;
$color-content-logged-out: $dark-color;
// global borders
$global-radius: 4px;
$global-padding: 16px;
$global-margin: 16px;
// inputs
$input-background: #FFFFFF;
$input-color: $dark-color;
$input-placeholder-color: #999999;
$input-border-color: #E7E7E7; //$light-color;
$input-height: 48px;
$form-width: 448px;
// buttons
$button-height: 48px;

:root {
    --theme-menu-opacity: linear-gradient(0deg, rgba(#{$bg-overlay-rgb}, 1) 0%, rgba(#{$bg-overlay-rgb}, 0.4) 20%, rgba(#{$bg-overlay-rgb}, 0.4) 85%, rgba(#{$bg-overlay-rgb}, 0.6) 100%);
    --ion-logo-menu: url('/assets/images/theme/logo.png') !important;
}

ion-menu ion-content .event-switch span {
    color: var(--ion-color-light) !important;
}

ion-menu ion-content .event-switch span svg-icon {
    fill: var(--ion-color-light) !important;
}

.header-overlay a {
    color: var(--ion-color-light) !important;
}

// // fonts
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.eot');
    src: local(''), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Merriweather') format('svg')
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot');
    src: local(''), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Merriweather') format('svg')
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.eot');
    src: local(''), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Merriweather') format('svg')
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.eot');
    src: local(''), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.ttf') format('truetype'), url('/assets/images/theme/fonts/merriweather-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Merriweather') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 200;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-200.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-300.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-regular.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-600.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-700.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.eot');
    src: local(''), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.woff2') format('woff2'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.woff') format('woff'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.ttf') format('truetype'), url('/assets/images/theme/fonts/titillium-web-v15-latin-ext_latin-900.svg#TitilliumWeb') format('svg')
}

@font-face {
    font-family: icomoon-the7-font;
    src: url(/assets/images/theme/fonts/icomoon-the7-font.ttf?wi57p5) format('truetype'), url(/assets/images/theme/fonts/icomoon-the7-font.woff?wi57p5) format('woff');
    font-weight: 400;
    font-style: normal
}

ion-alert.public {
    .alert-head {
        --ion-background-color: #e3e3e3;

        h2 {
            font-family: "Titillium Web", Helvetica, Arial, Verdana, sans-serif !important;
            font-size: 20px !important;
            line-height: 28px !important;
            font-weight: 400 !important;
            color: #000 !important;
        }
    }

    .alert-message {
        font-family: "Merriweather" !important;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: 400 !important;
        color: #000;
        background: rgba(227, 227, 227, .4) !important;
    }

    .alert-button-group {
        background: rgba(227, 227, 227, .4);

        button {
            font-family: "Titillium Web", Helvetica, Arial, Verdana, sans-serif !important;
            font-size: 23px !important;
            text-transform: none !important;
            background: #ffce44 !important;
            color: #00194d !important;

            &:hover {
                background: transparent linear-gradient(360deg, #dea600 0%, #ffce44 100%) !important;
            }
        }
    }
}

ion-toast.public.ion-color-danger {
    --ion-color-base: #175ce1 !important;
    --border-color: #175ce1 !important;
    margin: 40vh 0 0 12px !important;
    font-family: "Merriweather" !important;
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    --border-radius: 0;

    &::part(container) {
        padding-left: 44px;

        &:before {
            position: absolute;
            display: -ms-flexbox;
            display: -ms-flex;
            display: flex;
            -ms-flex-pack: center;
            -ms-justify-content: center;
            justify-content: center;
            top: 16px;
            left: 0;
            width: 65px;
            height: 100%;
            font-family: icomoon-the7-font;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            font-size: 18px;
            line-height: 1;
            content: "\ea10";
        }
    }
}